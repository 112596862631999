import React from "react";

import Navbar from "../components/Navbar.js";
import Header from "../components/Header.js";
import BannerServices from "../components/BannerServices.js";
import Slogan from "../components/Slogan.js";
import Directors from "../components/Directors.js";
import Clients from "../components/Clients.js";
import Footer from "../components/Footer.js";

export default function Index() {
  return (
    <>
      <Navbar/>
      <Header />
      <BannerServices />
      <Slogan />
      <Directors />
      <Clients />
      <Footer />
    </>
  );
}