import React from "react";

import Navbar from "../components/Navbar.js";
import Heading from "../components/Heading.js";
import ProjectsCatalog from "../components/ProjectsCatalog.js";
import Footer from "../components/Footer.js";

export default function Services() {
  return (
    <>
      <Navbar/>
      <Heading hImage={`url('assets/images/fondo_proyectos.jpg')`} hTitle={'Proyectos'}/>
      <ProjectsCatalog />
      <Footer />
    </>
  );
}