import React from 'react'

import ProjectItem from './ProjectItem';
import ProjectsList from '../projects-list';

export default function ProjectsCatalog(){
    function createProjectContainer(proyectos) {
        return (
            <ProjectItem 
                key={proyectos.id}
                pImage={process.env.PUBLIC_URL+proyectos.projectImage}
                pTitle={proyectos.projectTitle}
                pLocation={proyectos.projectLocation}
                pName={proyectos.projectName}
            />
        );
    }
    return(
        <>
            <section className="ftco-section goto-here">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 heading-section text-center mb-5">
                            <span className="subheading">NUESTROS</span>
                            <h2 className="mb-2">Proyectos</h2>
                        </div>
                    </div>
                    <div className="row">
                        {ProjectsList.map(createProjectContainer)}
                    </div>
                </div>
            </section>
        </>
    );
}