const protocolos = [
    {id: 1,
      protocolImage: `url("assets/images/miniaturas/t_alimenticios.JPG")`,
      protocolLink: "https://www.youtube-nocookie.com/embed/jL8wDakfM74?rel=0",
      protocolSubheading: "Psicología Laboral: Trastornos Alimenticios",
      protocolYear: "2021"
    },
    {id: 2,
    protocolImage: `url("assets/images/miniaturas/autismo.JPG")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/62ESKkw-Fqo?rel=0",
    protocolSubheading: "Psicología Laboral: Autismo",
    protocolYear: "2021"
    },
    {id: 3,
    protocolImage: `url("assets/images/miniaturas/tdah.JPG")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/9QixMqzrieQ?rel=0",
    protocolSubheading: "Psicología Laboral: TDAH",
    protocolYear: "2021"
    },
    {id: 3,
    protocolImage: `url("assets/images/miniaturas/tdah.JPG")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/9QixMqzrieQ?rel=0",
    protocolSubheading: "Psicología Laboral: TDAH",
    protocolYear: "2021"
    },
    {id: 4,
    protocolImage: `url("assets/images/miniaturas/estigmas.JPG")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/MFVkKTqmFOg?rel=0",
    protocolSubheading: "Psicología Laboral: Estigmas",
    protocolYear: "2021"
    },
    {id: 5,
    protocolImage: `url("assets/images/miniaturas/refuerzo_covid19.JPG")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/jMK1cXAVB3A?rel=0",
    protocolSubheading: "Campaña de Capacitación Refuerzo COVID-19",
    protocolYear: "2021"
    },
    {id: 6,
    protocolImage: `url("assets/images/miniaturas/altruismo.JPG")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/8TVBaPMYYv8?rel=0",
    protocolSubheading: "Psicología Laboral: Altruismo",
    protocolYear: "2021"
    },
    {id: 7,
    protocolImage: `url("assets/images/miniaturas/adicciones.JPG")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/0mv7FM8fzY8&t=18s?rel=0",
    protocolSubheading: "Psicología Laboral: Adicciones",
    protocolYear: "2021"
    },
    {id: 8,
    protocolImage: `url("assets/images/miniaturas/empatia.JPG")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/mKJNSF0j6dU?rel=0",
    protocolSubheading: "Psicología Laboral: Empatía",
    protocolYear: "2021"
    },
    {id: 9,
    protocolImage: `url("assets/images/miniaturas/t_mentales.JPG")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/0NUIEQi_o6Q?rel=0",
    protocolSubheading: "Psicología Laboral: Trastornos Mentales",
    protocolYear: "2021"
    },
    {id: 10,
    protocolImage: `url("assets/images/miniaturas/mobbing.JPG")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/D44BjjRsRNE?rel=0",
    protocolSubheading: "Psicología Laboral: Mobbing",
    protocolYear: "2021"
    },
    {id: 11,
    protocolImage: `url("assets/images/miniaturas/burnout.JPG")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/3PgmgrrM33Y?rel=0",
    protocolSubheading: "Psicología Laboral: Síndrome de Burnout",
    protocolYear: "2021"
    },
    {id: 12,
    protocolImage: `url("assets/images/miniaturas/resil.JPG")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/t0bEbeOOQzs?rel=0",
    protocolSubheading: "Psicología Laboral: Resiliencia",
    protocolYear: "2021"
    },
    {id: 13,
    protocolImage: `url("assets/images/miniaturas/saludM.JPG")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/gRPBLmmplmA?rel=0",
    protocolSubheading: "Psicología Laboral: Cuidando la Salud Mental",
    protocolYear: "2021"
    },
    {id: 14,
    protocolImage: `url("assets/images/miniaturas/pl_estres.JPG")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/0m4j3kYKTuE?rel=0",
    protocolSubheading: "Psicología Laboral: Estrés",
    protocolYear: "2020"
    },
    {id: 15,
    protocolImage: `url("assets/images/miniaturas/ej_rel.JPG")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/yTpqJ5cNn1A?rel=0",
    protocolSubheading: "Psicología Laboral: Ejercicios de Relajación",
    protocolYear: "2020"
    },
    {id: 16,
    protocolImage: `url("assets/images/miniaturas/camp_ref.png")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/KlxaVVA2KVs?rel=0",
    protocolSubheading: "Campaña de Reforzamiento de Medidas Preventivas COVID-19",
    protocolYear: "2020"
    },
    {id: 17,
    protocolImage: `url("assets/images/miniaturas/sabinas.png")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/TEO6woQXvBE?rel=0",
    protocolSubheading: "Unidad Sabinas: Aplicación de políticas temporales ante el COVID-19",
    protocolYear: "2020"
    },
    {id: 18,
    protocolImage: `url("assets/images/miniaturas/ansiedad.png")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/p6gqzu64SuY?rel=0",
    protocolSubheading: "Tips para afrontar la ansiedad ante el COVID-19",
    protocolYear: "2020"
    },
    {id: 19,
    protocolImage: `url("assets/images/miniaturas/sanitizacion.png")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/KG3oYPMHXCE?rel=0",
    protocolSubheading: "Sanitización y Desinfección de Áreas en el Centro de Trabajo",
    protocolYear: "2020"
    },
    {id: 20,
    protocolImage: `url("assets/images/miniaturas/epp.png")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/F_1huJuqYWY?rel=0",
    protocolSubheading: "Administración de los procesos y EPP ante el COVID-19",
    protocolYear: "2020"
    },
    {id: 21,
    protocolImage: `url("assets/images/miniaturas/normalidad.png")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/-kepLNsP3f4?rel=0",
    protocolSubheading: "Retorno Seguro Nueva Normalidad",
    protocolYear: "2020"
    },
    {id: 22,
    protocolImage: `url("assets/images/miniaturas/saucito.png")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/Lb-tvYilXYQ?rel=0",
    protocolSubheading: "Juntos contra el COVID-19 Unidad Saucito",
    protocolYear: "2020"
    },
    {id: 23,
    protocolImage: `url("assets/images/miniaturas/sanJulian.png")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/2F8mHvYm-LI?rel=0",
    protocolSubheading: "Juntos contra el COVID-19 Proyecto San Julián",
    protocolYear: "2020"
    },
    {id: 24,
    protocolImage: `url("assets/images/miniaturas/mensaje_seg.png")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/6-v6DcoNRZY?rel=0",
    protocolSubheading: "Mensaje Coordinadores de Seguridad",
    protocolYear: "2020"
    },
    {id: 25,
    protocolImage: `url("assets/images/miniaturas/mensaje_arq.png")`,
    protocolLink: "https://www.youtube-nocookie.com/embed/whsd4kOGEaM?rel=0",
    protocolSubheading: "Mensaje Arq. Ignacio Castrejón",
    protocolYear: "2020"
    }
  ];
  
export default protocolos;