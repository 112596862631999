import React from "react";

import Navbar from "../components/Navbar.js";
import Heading from "../components/Heading.js";
import Directors from "../components/Directors.js";
import Clients from "../components/Clients.js";
import Footer from "../components/Footer.js";

export default function About() {
  return (
    <>
      <Navbar/>
      <Heading hImage={`url('assets/images/fondo_about.jpg')`} hTitle={'Acerca de'}/>
      <Directors />
      <Clients />
      <Footer />
    </>
  );
}