import React from 'react'

export default function ProjectItem(props){
    return(
        <>
            <div className="col-md-4">
				<div className="property-wrap">
					<a className="img" href="/">
                        <img src={props.pImage} width="387" height="250" alt="..."></img>
					</a>
					<div className="text">
						<h3><a href>{props.pName}</a></h3>
						<span className="location">{props.pLocation}</span>
						<div className="list-team d-flex align-items-center mt-2 pt-2 border-top">
							<div className="d-flex align-items-center">
								<h3 className="ml-2">{props.pTitle}</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
        </>
    );
}