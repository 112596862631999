import React from 'react'

export default function ProtocolCovid(props){
    return(
        <>
            <div className="col-md-4">
				<div className="property-details">
					<div className="img" style={{backgroundImage: props.pImage, height: '217px'}}>
						<a href={props.pLink} className="img-video popup-vimeo d-flex align-items-center justify-content-center">
						<span className="fa fa-play"></span>
					  </a>
					</div>
					<div className="text">
						<span className="subheading">{props.pSubheading}</span>
						<h2>{props.pYear}</h2>
					</div>
				</div>
			</div>
        </>
    )
}