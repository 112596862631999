import React from 'react'

export default function Header(){
    return(
        <>
            <section className="hero-wrap" style={{backgroundImage: `url('assets/images/fondo_inicio.jpg')`}} data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center">
                        <div className="col-lg-7 col-md-6 d-flex align-items-end">
                            <div className="text">
                                <h1 className="mb-4">Bienvenido</h1>
                                <p style={{fontSize: 18}}>En GRUPO CAVI nuestro compromiso con nuestros clientes es otorgarles satisfacción, seguridad y garantía.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}