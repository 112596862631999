import React from 'react'

import Navbar from "../components/Navbar.js";
import Heading from "../components/Heading.js";
import SustainabilityCatalog from "../components/SustainabilityCatalog.js";
import Footer from "../components/Footer.js";

export default function Sustainability(){
    return(
        <>
        <Navbar />
        <Heading hImage={`url('assets/images/fondo_sostenibilidad.jpg')`} hTitle={'Sostenibilidad'}/>
        <SustainabilityCatalog />
        <Footer />
        </>
    );
}