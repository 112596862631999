const proyectos = [
    {id: 1,
      videoImage: `url("assets/images/miniaturas/20_aniv.png")`,
      videoLink: "https://www.youtube-nocookie.com/embed/ATqZh8UqJIM?rel=0",
      videoName: "20 Aniversario Zonas Sabinas y Saucito",
      videoYear: "2022"
    },
    {id: 2,
        videoImage: `url("assets/images/miniaturas/20_aniv.png")`,
        videoLink: "https://www.youtube-nocookie.com/embed/yZBbMlgx_yE?rel=0",
        videoName: "20 Aniversario Fases II y III",
        videoYear: "2022"
    },
    {id: 3,
        videoImage: `url("assets/images/miniaturas/20_aniv.png")`,
        videoLink: "https://www.youtube-nocookie.com/embed/ze1nqF0FktM?rel=0",
        videoName: "20 Aniversario Fase I",
        videoYear: "2022"
    },
    {id: 4,
        videoImage: `url("assets/images/miniaturas/prot_dabe.JPG")`,
        videoLink: "https://www.youtube-nocookie.com/embed/OrCHUDkaLUs?rel=0",
        videoName: "Protocolo DABE",
        videoYear: "2022"
    },
    {id: 5,
        videoImage: `url("assets/images/miniaturas/manejo_defensiva.JPG")`,
        videoLink: "https://www.youtube-nocookie.com/embed/7VfOnl2v_kY?rel=0",
        videoName: "Curso Manejo a la Defensiva",
        videoYear: "2021"
    },
    {id: 6,
        videoImage: `url("assets/images/miniaturas/proc_cg_lainas.JPG")`,
        videoLink: "https://www.youtube-nocookie.com/embed/i5FkRDx7kYk?rel=0",
        videoName: "Procedimiento de Cambio y Giro de Lainas de Quebradora de Quijada",
        videoYear: "2021"
    },
    {id: 7,
        videoImage: `url("assets/images/miniaturas/t_alturas_saucito.JPG")`,
        videoLink: "https://www.youtube-nocookie.com/embed/Eb6udlu_DS4?rel=0",
        videoName: "Trabajos en Alturas Unidad Saucito",
        videoYear: "2021"
    },
    {id: 8,
        videoImage: `url("assets/images/miniaturas/reub_plomo_cobre.JPG")`,
        videoLink: "https://www.youtube-nocookie.com/embed/kUpjHoHR_Hw?rel=0",
        videoName: "Reubicación de Depósito de Agua para Filtro de Plomo-Cobre",
        videoYear: "2021"
    },
    {id: 9,
        videoImage: `url("assets/images/miniaturas/dia_padre2021.JPG")`,
        videoLink: "https://www.youtube-nocookie.com/embed/rt03yVyoI4U?rel=0",
        videoName: "Día del Padre",
        videoYear: "2021"
    },
    {id: 10,
        videoImage: `url("assets/images/miniaturas/proc_inst_tuberias.JPG")`,
        videoLink: "https://www.youtube-nocookie.com/embed/cJfw7QwGao4?rel=0",
        videoName: "Procedimiento para Instalación de Tuberías",
        videoYear: "2021"
    },
    {id: 11,
        videoImage: `url("assets/images/miniaturas/presion_arterial.JPG")`,
        videoLink: "https://www.youtube-nocookie.com/embed/nXmKKy0sgl8?rel=0",
        videoName: "Presión Arterial",
        videoYear: "2021"
    },
    {id: 12,
        videoImage: `url("assets/images/miniaturas/proc_veh_livianos.JPG")`,
        videoLink: "https://www.youtube-nocookie.com/embed/3_NfABcTxIc?rel=0",
        videoName: "Procedimiento de Vehículos Livianos",
        videoYear: "2021"
    },
    {id: 13,
        videoImage: `url("assets/images/miniaturas/tarjetas_iper.JPG")`,
        videoLink: "https://www.youtube-nocookie.com/embed/TMSwZaSNiUg?rel=0",
        videoName: "Uso de Tarjetas Iper Unidad La Herradura",
        videoYear: "2021"
    },
    {id: 14,
        videoImage: `url("assets/images/miniaturas/dia_madres2021.JPG")`,
        videoLink: "https://www.youtube-nocookie.com/embed/oQAaxQVWX0E?rel=0",
        videoName: "Día de las Madres",
        videoYear: "2021"
    },
    {id: 15,
        videoImage: `url("assets/images/miniaturas/proc_corte_sold.JPG")`,
        videoLink: "https://www.youtube-nocookie.com/embed/WDDEUn1BxwM?rel=0",
        videoName: "Procedimientos de Corte y Soldadura",
        videoYear: "2021"
    },
    {id: 16,
        videoImage: `url("assets/images/miniaturas/cianuro_saucito.JPG")`,
        videoLink: "https://www.youtube-nocookie.com/embed/PGE1yuH4Rhc?rel=0",
        videoName: "Manejo Seguro de Cianuro Unidad Saucito",
        videoYear: "2021"
    },
    {id: 17,
        videoImage: `url("assets/images/miniaturas/seg_preventiva.JPG")`,
        videoLink: "https://www.youtube-nocookie.com/embed/yWU0-N2Kxj8?rel=0",
        videoName: "Seguridad Preventiva Obra Civil San Julián",
        videoYear: "2021"
    },
    {id: 18,
        videoImage: `url("assets/images/miniaturas/dia_mujer2021.JPG")`,
        videoLink: "https://www.youtube-nocookie.com/embed/IOsHf7djIPc?rel=0",
        videoName: "Día Internacional de la Mujer",
        videoYear: "2021"
    },
    {id: 19,
        videoImage: `url("assets/images/miniaturas/rit_uqr.JPG")`,
        videoLink: "https://www.youtube-nocookie.com/embed/nlsSt7kMM2k?rel=0",
        videoName: "RIT Unidad Químicas del Rey",
        videoYear: "2021"
    },
    {id: 20,
        videoImage: `url("assets/images/miniaturas/incendios.JPG")`,
        videoLink: "https://www.youtube-nocookie.com/embed/KgtYE5TuFoo?rel=0",
        videoName: "Prevención y Combate de Incendios",
        videoYear: "2021"
    },
    {id: 21,
        videoImage: `url("assets/images/miniaturas/cap_adi.JPG")`,
        videoLink: "https://www.youtube-nocookie.com/embed/QnA1NpJ3NLc?rel=0",
        videoName: "Capacitación y Adiestramiento",
        videoYear: "2021"
    },
    {id: 22,
        videoImage: `url("assets/images/miniaturas/NOM2.JPG")`,
        videoLink: "https://www.youtube-nocookie.com/embed/yqNydXddrRI?rel=0",
        videoName: "Seguridad: NOM-002",
        videoYear: "2021"
    },
    {id: 23,
        videoImage: `url("assets/images/miniaturas/nav2020.JPG")`,
        videoLink: "https://www.youtube-nocookie.com/embed/oStr047uMJo?rel=0",
        videoName: "¡Feliz Navidad!",
        videoYear: "2020"
    },
    {id: 24,
        videoImage: `url("assets/images/miniaturas/seg_csol.JPG")`,
        videoLink: "https://www.youtube-nocookie.com/embed/7OK14OHvOww?rel=0",
        videoName: "Seguridad: Corte y Soldadura",
        videoYear: "2020"
    },
    {id: 25,
        videoImage: `url("assets/images/miniaturas/c_gerentes.JPG")`,
        videoLink: "https://www.youtube-nocookie.com/embed/e_WzUYRVKOw?rel=0",
        videoName: "Capacitación a Gerentes",
        videoYear: "2020"
    },
    {id: 26,
        videoImage: `url("assets/images/miniaturas/d_minero.png")`,
        videoLink: "https://www.youtube-nocookie.com/embed/OHCTF-DDqd8?rel=0",
        videoName: "Día del Minero",
        videoYear: "2020"
    },
    {id: 27,
        videoImage: `url("assets/images/miniaturas/d_padre.png")`,
        videoLink: "https://www.youtube-nocookie.com/embed/6YJcx1f-mcM?rel=0",
        videoName: "Día del Padre",
        videoYear: "2020"
    },
    {id: 28,
        videoImage: `url("assets/images/miniaturas/d_madre.png")`,
        videoLink: "https://www.youtube-nocookie.com/embed/Ln1vpTLSwRg?rel=0",
        videoName: "Día de las Madres",
        videoYear: "2020"
    },
    {id: 29,
        videoImage: `url("assets/images/miniaturas/c_manos2.png")`,
        videoLink: "https://www.youtube-nocookie.com/embed/HSlUU4vNq30?rel=0",
        videoName: '"Alto, por ti y tu familia, Cuida tus manos" Segunda Parte',
        videoYear: "2020"
    },
    {id: 30,
        videoImage: `url("assets/images/miniaturas/d_mujer.png")`,
        videoLink: "https://www.youtube-nocookie.com/embed/AllXLXuMhDU?rel=0",
        videoName: "Día Internacional de la Mujer",
        videoYear: "2020"
    },
    {id: 31,
        videoImage: `url("assets/images/miniaturas/rit_2020.png")`,
        videoLink: "https://www.youtube-nocookie.com/embed/9aA76-ffGTs?rel=0",
        videoName: "RIT Enero y Febrero",
        videoYear: "2020"
    },
    {id: 32,
        videoImage: `url("assets/images/miniaturas/d_familia.png")`,
        videoLink: "https://www.youtube-nocookie.com/embed/a1VYIbPoWco?rel=0",
        videoName: "Día de la Familia",
        videoYear: "2020"
    },
    {id: 33,
        videoImage: `url("assets/images/miniaturas/c_manos1.png")`,
        videoLink: "https://www.youtube-nocookie.com/embed/iViSqHS9v_4?rel=0",
        videoName: '2° Campaña de Seguridad "Alto, por ti y tu familia, Cuida tus manos"',
        videoYear: "2020"
    },
    {id: 34,
        videoImage: `url("assets/images/miniaturas/cam.png")`,
        videoLink: "https://www.youtube-nocookie.com/embed/JflJgYUnRyw?rel=0",
        videoName: "Grupo Cavi visita al CAM",
        videoYear: "2020"
    },
    {id: 35,
        videoImage: `url("assets/images/miniaturas/concretos.png")`,
        videoLink: "https://www.youtube-nocookie.com/embed/yWJei33gZ78?rel=0",
        videoName: "Concretos Cavi",
        videoYear: "2020"
    },
    {id: 36,
        videoImage: `url("assets/images/miniaturas/fin_2019.png")`,
        videoLink: "https://www.youtube-nocookie.com/embed/O9sBUGlxUM8?rel=0",
        videoName: "Cena de Fin de Año 2019",
        videoYear: "2019"
    },
    {id: 37,
        videoImage: `url("assets/images/miniaturas/cavi17.png")`,
        videoLink: "https://www.youtube-nocookie.com/embed/ebiQgSGAR28?rel=0",
        videoName: "Grupo Cavi 17 Aniversario",
        videoYear: "2019"
    },
    {id: 38,
        videoImage: `url("assets/images/miniaturas/cavi15.png")`,
        videoLink: "https://www.youtube-nocookie.com/embed/PvTZ-lkazGM?rel=0",
        videoName: "Grupo Cavi XV Aniversario",
        videoYear: "2017"
    },
    {id: 39,
        videoImage: `url("assets/images/miniaturas/cavi15_fest.png")`,
        videoLink: "https://www.youtube-nocookie.com/embed/YcbyL7Fnzwk?rel=0",
        videoName: "Festejo XV Aniversario Grupo Cavi",
        videoYear: "2017"
    },
    {id: 40,
        videoImage: `url("assets/images/miniaturas/tocayos.png")`,
        videoLink: "https://www.youtube-nocookie.com/embed/mNi9YPof3Qk?rel=0",
        videoName: "Construcción y Rehabilitación de Presa de Jales Tocayos",
        videoYear: "2013"
    },
    {id: 41,
        videoImage: `url("assets/images/miniaturas/cavi2013.png")`,
        videoLink: "https://www.youtube-nocookie.com/embed/SFX-IjfVB68?rel=0",
        videoName: "Grupo Cavi 2013",
        videoYear: "2013"
    },
  ];
  
export default proyectos;