const cavimacSlider = [
    {id: 1,slImage: 'assets/images/man7.JPG'},
    {id: 2,slImage: 'assets/images/man2.jpg'},
    {id: 3,slImage: 'assets/images/man5.JPG'},
    {id: 4,slImage: 'assets/images/man4.JPG'},
    {id: 5,slImage: 'assets/images/man3.JPG'},
    {id: 6,slImage: 'assets/images/man6.JPG'},
    {id: 7,slImage: 'assets/images/man1.jpg'}
  ];
  
export default cavimacSlider;