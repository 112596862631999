import React from 'react'

import VideoItem from './VideoItem';
import VideosList from '../videos-list';

export default function VideosCatalog(){
    function createVideoContainer(video) {
        return (
            <VideoItem 
                key={video.id}
                vThumbnail={process.env.PUBLIC_URL+video.videoImage}
                vLink={video.videoLink}
                vName={video.videoName}
                vYear={video.videoYear}
            />
        );
    }
    return(
        <>
            <section className="ftco-section ftco-property-details">
                <div className="container">
                    <div className="row justify-content-center">
                        {VideosList.map(createVideoContainer)}
                    </div>
                </div>
            </section>
        </>
    );
}