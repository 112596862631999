const proyectos = [
    {id: 1,
      projectImage: "assets/images/proyectos/la_noria.jpg",
      projectTitle: "La Noria",
      projectLocation: "La Noria, Sombrerete, Zacatecas",
      projectName: "Depósito de Jales La Noria"
    },
    {id: 2,
      projectImage: "assets/images/proyectos/psbm.jpg",
      projectTitle: "Unidades Mineras",
      projectLocation: "Plantas y Unidades Mineras",
      projectName: "Pailería, Soldadura y Blindaje de Molinos"
    },
    {id: 3,
      projectImage: "assets/images/proyectos/tuberia_red.jpg",
      projectTitle: "Minera San Julián",
      projectLocation: "Guadalupe y Calvo, Chihuahua",
      projectName: "Tubería Red contra Incendios"
    },
    {id: 4,
      projectImage: "assets/images/proyectos/techumbre.jpg",
      projectTitle: "Minera San Julián",
      projectLocation: "Guadalupe y Calvo, Chihuahua",
      projectName: "Techumbre Almacén General"
    },
    {id: 5,
      projectImage: "assets/images/proyectos/tanque_presa.jpg",
      projectTitle: "Minera San Julián",
      projectLocation: "Guadalupe y Calvo, Chihuahua",
      projectName: "Tanque para Presa de Agua"
    },
    {id: 6,
      projectImage: "assets/images/proyectos/robin_tepetatero.jpg",
      projectTitle: "Minera San Julián",
      projectLocation: "Guadalupe y Calvo, Chihuahua",
      projectName: "Robbin Tepetatero"
    },
    {id: 7,
      projectImage: "assets/images/proyectos/robin_ventilacion.jpg",
      projectTitle: "Minera San Julián",
      projectLocation: "Guadalupe y Calvo, Chihuahua",
      projectName: "Robbin de Ventilación RV-37"
    },
    {id: 8,
      projectImage: "assets/images/proyectos/puente_stacecilia.jpg",
      projectTitle: "Minera San Julián",
      projectLocation: "Guadalupe y Calvo, Chihuahua",
      projectName: "Puente Santa Cecilia"
    },
    {id: 9,
      projectImage: "assets/images/proyectos/plataformas_estacion.jpg",
      projectTitle: "Minera San Julián",
      projectLocation: "Guadalupe y Calvo, Chihuahua",
      projectName: "Plataformas Estación Diesel Fllo"
    },
    {id: 10,
      projectImage: "assets/images/proyectos/piletas_dura.jpg",
      projectTitle: "Minera San Julián",
      projectLocation: "Guadalupe y Calvo, Chihuahua",
      projectName: "Piletas La Dura"
    },
    {id: 11,
      projectImage: "assets/images/proyectos/pileta_santos.jpg",
      projectTitle: "Minera San Julián",
      projectLocation: "Guadalupe y Calvo, Chihuahua",
      projectName: "Pileta Todos Santos"
    },
    {id: 12,
      projectImage: "assets/images/proyectos/pileta_rv81.jpg",
      projectTitle: "Minera San Julián",
      projectLocation: "Guadalupe y Calvo, Chihuahua",
      projectName: "Pileta RV-81"
    },
    {id: 13,
      projectImage: "assets/images/proyectos/interior_mina.jpg",
      projectTitle: "Minera San Julián",
      projectLocation: "Guadalupe y Calvo, Chihuahua",
      projectName: "Interior Mina"
    },
    {id: 14,
      projectImage: "assets/images/proyectos/electroiman.jpg",
      projectTitle: "Minera San Julián",
      projectLocation: "Guadalupe y Calvo, Chihuahua",
      projectName: "Electroimán JM"
    },
    {id: 15,
      projectImage: "assets/images/proyectos/dado_martillo.jpg",
      projectTitle: "Minera San Julián",
      projectLocation: "Guadalupe y Calvo, Chihuahua",
      projectName: "Dado Martillo JM"
    },
    {id: 16,
      projectImage: "assets/images/proyectos/centro_adiestramiento.jpg",
      projectTitle: "Minera San Julián",
      projectLocation: "Guadalupe y Calvo, Chihuahua",
      projectName: "Centro de Adiestramiento"
    },
    {id: 17,
      projectImage: "assets/images/proyectos/saucito_mtto.jpg",
      projectTitle: "Minera Saucito",
      projectLocation: "Fresnillo, Zacatecas",
      projectName: "Saucito Mantenimiento"
    },
    {id: 18,
      projectImage: "assets/images/proyectos/proyecto_sj.jpg",
      projectTitle: "Minera San Julián",
      projectLocation: "Guadalupe y Calvo, Chihuahua",
      projectName: "Proyecto San Julián"
    },
    {id: 19,
      projectImage: "assets/images/proyectos/caseta_saucito.jpg",
      projectTitle: "Minera Saucito",
      projectLocation: "Fresnillo, Zacatecas",
      projectName: "Caseta"
    },
    {id: 20,
      projectImage: "assets/images/proyectos/bases_zitron.jpg",
      projectTitle: "Minera Saucito",
      projectLocation: "Fresnillo, Zacatecas",
      projectName: "Bases de Zitron"
    },
    {id: 21,
      projectImage: "assets/images/proyectos/celdas_saucito.jpg",
      projectTitle: "Minera Saucito",
      projectLocation: "Fresnillo, Zacatecas",
      projectName: "Instalación de Celdas"
    },
    {id: 22,
      projectImage: "assets/images/proyectos/mtto_saucito_jun2019.jpg",
      projectTitle: "Minera Saucito",
      projectLocation: "Fresnillo, Zacatecas",
      projectName: "Mantenimiento Junio 2019"
    },
    {id: 23,
      projectImage: "assets/images/proyectos/paro_mzo2019.jpg",
      projectTitle: "Minera Saucito",
      projectLocation: "Fresnillo, Zacatecas",
      projectName: "Paro Marzo 2019"
    },
    {id: 23,
      projectImage: "assets/images/proyectos/nave_industrial.jpg",
      projectTitle: "Sombrerete",
      projectLocation: "Sombrerete, Zacatecas",
      projectName: "Nave Industrial CAVI"
    },
    {id: 24,
      projectImage: "assets/images/proyectos/mtto_sabinas.jpg",
      projectTitle: "Minera Sabinas",
      projectLocation: "Sombrerete, Zacatecas",
      projectName: "Mantenimiento"
    },
    {id: 25,
      projectImage: "assets/images/proyectos/proceso_jales.jpg",
      projectTitle: "Minera San Francisco del Oro",
      projectLocation: "San Fco. del Oro, Chihuahua",
      projectName: "Proceso de Jales"
    },
    {id: 26,
      projectImage: "assets/images/proyectos/aula.jpg",
      projectTitle: "Sain Alto",
      projectLocation: "Sain Alto, Zacatecas",
      projectName: "Aula"
    },
    {id: 27,
      projectImage: "assets/images/proyectos/mtto_gral.jpg",
      projectTitle: "Minera Sabinas",
      projectLocation: "Sombrerete, Zacatecas",
      projectName: "Mantenimiento General"
    },
    {id: 28,
      projectImage: "assets/images/proyectos/campamento_minero.jpg",
      projectTitle: "Minera La Ciénega",
      projectLocation: "San Ramón, Durango",
      projectName: "Campamento Minero"
    },
    {id: 29,
      projectImage: "assets/images/proyectos/minera_madero.jpg",
      projectTitle: "Minera Madero",
      projectLocation: "Morelos, Zacatecas",
      projectName: "Trabajos en Minera Madero"
    },
    {id: 30,
      projectImage: "assets/images/proyectos/cienega.jpg",
      projectTitle: "Minera La Ciénega",
      projectLocation: "San Ramón, Durango",
      projectName: "Trabajos en Ciénega"
    },
    {id: 31,
      projectImage: "assets/images/proyectos/casas_muestra.jpg",
      projectTitle: "Sombrerete",
      projectLocation: "Sombrerete, Zacatecas",
      projectName: "Casas Muestra"
    },
    {id: 32,
      projectImage: "assets/images/proyectos/espesador.jpg",
      projectTitle: "Minera Bismark",
      projectLocation: "Ascención, Chihuahua",
      projectName: "Espesador"
    },
    {id: 33,
      projectImage: "assets/images/proyectos/mejoras_lixiviacion.jpg",
      projectTitle: "Minera La Ciénega",
      projectLocation: "San Ramón, Durango",
      projectName: "Mejoras Área de Lixiviación"
    },
    {id: 34,
      projectImage: "assets/images/proyectos/muro_interior.jpg",
      projectTitle: "Minera Tizapa",
      projectLocation: "Zacazonapan, Estado de México",
      projectName: "Muro Interior"
    },
    {id: 35,
      projectImage: "assets/images/proyectos/instalacion_tuberias.jpg",
      projectTitle: "Minera La Ciénega",
      projectLocation: "San Ramón, Durango",
      projectName: "Instalación de Tuberías"
    },
    {id: 36,
      projectImage: "assets/images/proyectos/celdas.jpg",
      projectTitle: "Minera Tizapa",
      projectLocation: "Zacazonapan, Estado de México",
      projectName: "Celdas"
    },
    {id: 37,
      projectImage: "assets/images/proyectos/espesador_rey_plata.jpg",
      projectTitle: "Minera Rey de Plata",
      projectLocation: "Teloloapan, Guerrero",
      projectName: "Espesador Rey de Plata"
    },
    {id: 38,
      projectImage: "assets/images/proyectos/casa_habitacion.jpg",
      projectTitle: "Sombrerete",
      projectLocation: "Sombrerete, Zacatecas",
      projectName: "Construcción Casa Habitación"
    },
    {id: 39,
      projectImage: "assets/images/proyectos/cuarto_bombas.jpg",
      projectTitle: "Minera Sabinas",
      projectLocation: "Sombrerete, Zacatecas",
      projectName: "Cuarto de Bombas"
    },
    {id: 40,
      projectImage: "assets/images/proyectos/mantenimiento_saucito.jpg",
      projectTitle: "Minera Saucito",
      projectLocation: "Fresnillo, Zacatecas",
      projectName: "Mantenimiento"
    },
    {id: 41,
      projectImage: "assets/images/proyectos/presa_parrilla.jpg",
      projectTitle: "Minera La Parrilla",
      projectLocation: "Nombre de Dios, Durango",
      projectName: "Presa de Jales"
    },
    {id: 42,
      projectImage: "assets/images/proyectos/limpieza_embarque.jpg",
      projectTitle: "Minera Saucito",
      projectLocation: "Fresnillo, Zacatecas",
      projectName: "Limpieza y Embarques"
    },
    {id: 43,
      projectImage: "assets/images/proyectos/rehab_tocayos.jpg",
      projectTitle: "Mina Tocayos",
      projectLocation: "Sombrerete, Zacatecas",
      projectName: "Rehabilitación Presa de Jales"
    },
    {id: 44,
      projectImage: "assets/images/proyectos/pileta_saucillo.jpg",
      projectTitle: "Minera Saucito",
      projectLocation: "Fresnillo, Zacatecas",
      projectName: "Pileta Saucillo"
    },
    {id: 45,
      projectImage: "assets/images/proyectos/el_pilon.jpg",
      projectTitle: "Minera El Pilón",
      projectLocation: "San Martín de Bolaños, Jalisco",
      projectName: "El Pilón"
    }
  ];
  
export default proyectos;