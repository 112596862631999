import React from 'react'

import PostItem from './PostItem';
import SustainabilityList from '../sustainability-list';

export default function SustainabilityCatalog(){
    function createPost(post) {
        return (
            <PostItem 
                key={post.id}
                pImage={process.env.PUBLIC_URL+post.postImage}
                pName={post.postName}
                pYear={post.postYear}
            />
        );
    }
    return(
        <>
            <section class="ftco-section">
		        <div class="container">
		            <div class="row d-flex">
                        {SustainabilityList.map(createPost)}
                    </div>
                </div>
            </section>
        </>
    );
}