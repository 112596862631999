import React from 'react'
import ClientMin
 from './ClientMin';
export default function Clients(){
    return(
        <>
            <section className="ftco-counter img">
                <div className="container">
                    <div className="row d-flex">
                    <div className="col-md-4 d-flex" align="center"></div>
                        <div className="col-md-4 d-flex" align="center">
                            <div className="blog-entry justify-content-end">
                            <div className="text">
                                <a className="block-20 img" href="https://https://clusmin.org/">
                                    <img
                                        alt="..."
                                        src={process.env.PUBLIC_URL+"assets/images/clusmin.png"}
                                        width="300"
                                        height="200" >
                                    </img>
                                </a>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex" align="center"></div>
                    </div>
                </div>
            </section>
            <section class="ftco-section">
                <div class="container">
                    <div class="row justify-content-center mb-5">
                        <div class="col-md-7 heading-section text-center">
                            <span class="subheading">Calidad</span>
                            <h2>Certificaciones</h2>
                        </div>
                    </div>
                    <div class="row d-flex">
                        <ClientMin cmImage={process.env.PUBLIC_URL+"/assets/images/logo_covid.png"} cmWith={320} cmHeight={200}/>
                        <ClientMin cmImage={process.env.PUBLIC_URL+"/assets/images/100.jpg"} cmWith={320} cmHeight={200}/>
                        <ClientMin cmImage={process.env.PUBLIC_URL+"/assets/images/passt_logo.jpg"} cmWith={180} cmHeight={200}/>
                    </div>
                </div>
            </section>
        </>
    );
}