import React from 'react'

export default function CovidFeature(props){
    return(
        <>
            <div className="col-md-4">
                <ul className="features">
                    <li className="check"><span className="fa fa-check-circle"></span>{props.Name1}</li>
                    <li className="check"><span className="fa fa-check-circle"></span>{props.Name2}</li>
                    <li className="check"><span className="fa fa-check-circle"></span>{props.Name3}</li>
                    <li className="check"><span className="fa fa-check-circle"></span>{props.Name4}</li>
                    <li className="check"><span className="fa fa-check-circle"></span>{props.Name5}</li>
                </ul>
            </div>
        </>
    )
}