import React from 'react'

import ServiceCard from './ServiceCard';

export default function BannerServices(){
    return(
        <>
            <section className="ftco-section ftco-no-pb ftco-no-pt bg-primary">
                <div className="container">
                    <div className="row d-flex no-gutters">
                        <ServiceCard
                            cardIcon="flaticon-man-general"
                            cardTitle={'Mantenimiento General'}
                            cardContent={'Montajes de equipos, instalación de tubería, construcción en plantas de beneficio, pailería industrial, fabricación de estructuras y obra civil.'}
                        />
                        <ServiceCard
                            cardIcon="flaticon-man-correctivo"
                            cardTitle={'Mantenimiento Correctivo'}
                            cardContent={'Mantenimiento a plantas, líneas de operación especializada en eléctrico, mecánico e instrumentista.'}
                        />
                        <ServiceCard
                            cardIcon="flaticon-maquinaria"
                            cardTitle={'Renta de Maquinaria'}
                            cardContent={'Servicio de planeación y ejecución de proyectos en tu área de trabajo y alquiler de maquinaria pesada.'}
                        />
                        <ServiceCard
                            cardIcon="flaticon-concreto"
                            cardTitle={'Concreto Premezclado'}
                            cardContent={'Concretos especiales para que lleves a cabo tus proyectos, productos pensados para cada necesidad de construcción.'}
                        />
                    </div>
                </div>
            </section>
        </>
    );
}