import React from 'react'

export default function PostItem(props){
    return(
        <>
            <div className="col-md-4 d-flex">
				<div className="blog-entry justify-content-end">
				  <div className="text">
					<a className="block-20 img" href="/">
                        <img src={props.pImage} width="403" height="270" alt="..."></img>
                    </a>
					<div className="meta mb-3">
						<div><a href="/">{props.pYear}</a></div>
					 </div>
					  <h3 className="heading"><a href="/">{props.pName}</a></h3>
				  </div>
				</div>
			 </div>
        </>
    );
}