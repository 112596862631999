import React from 'react'

export default function ClientMin(props){
    return(
        <>
            <div class="col-md-4 d-flex" align="center">
                <div class="blog-entry">
                    <div class="text">
                        <a class="block-20 img" href="/">
                            <img alt="..." src={props.cmImage} width={props.cmWidth} height={props.cmHeight}></img>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}