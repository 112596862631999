import React from 'react'

export default function Slogan(){
    return(
        <>
            <section className="ftco-section services-section bg-darken">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center heading-section heading-section-white">
                            <span className="subheading">Nuestro lema</span>
                            <br></br>
                            <br></br>
                            <br></br>
                            <h2 style={{fontStyle: 'italic'}}>"Cumpliendo expectativas, trabajando fuerte"</h2>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}