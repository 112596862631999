const sustainPosts = [
    {id: 1,
      postImage: "assets/images/sostenibilidad/20aniv_sabsau.jpg",
      postYear: "Julio, 2022",
      postName: "20 Aniversario Zona Sabinas y Zona Saucito",
    },
    {id: 2,
        postImage: "assets/images/sostenibilidad/20aniv_fase2.jpg",
        postYear: "Julio, 2022",
        postName: "20 Aniversario Fases II y III",
    },
    {id: 3,
        postImage: "assets/images/sostenibilidad/20aniv_fase1.jpg",
        postYear: "Junio, 2022",
        postName: "20 Aniversario Fase I",
    },
    {id: 4,
        postImage: "assets/images/sostenibilidad/navidad_2021.jpg",
        postYear: "Diciembre, 2021",
        postName: "¡Feliz Navidad 2021!",
    },
    {id: 5,
        postImage: "assets/images/sostenibilidad/navidad_2020.jpg",
        postYear: "Diciembre, 2020",
        postName: "¡Feliz Navidad 2020!",
    },
    {id: 6,
        postImage: "assets/images/sostenibilidad/peligros_riesgos.jpg",
        postYear: "Noviembre, 2020",
        postName: "Identificación de Peligros y evaluación de Riesgos",
    },
    {id: 7,
        postImage: "assets/images/sostenibilidad/mandos_medios.jpg",
        postYear: "Noviembre, 2020",
        postName: "Capacitación a Mandos Medios - Obligaciones del Patrón",
    },
    {id: 8,
        postImage: "assets/images/sostenibilidad/cap_seg_oc.jpg",
        postYear: "Noviembre, 2020",
        postName: "Condiciones de Seguridad para Trabajos de Obra Civil",
    },
    {id: 9,
        postImage: "assets/images/sostenibilidad/uso_conservacion.jpg",
        postYear: "Noviembre, 2020",
        postName: "Usos y Conservación de las Áreas y Protección contra Incendios",
    },
    {id: 10,
        postImage: "assets/images/sostenibilidad/cap_induccion_ss.jpg",
        postYear: "Noviembre, 2020",
        postName: "Capacitación de Inducción Seguridad e Higiene",
    },
    {id: 11,
        postImage: "assets/images/sostenibilidad/capacitacion_anual.jpg",
        postYear: "Noviembre, 2020",
        postName: "Capacitación Anual Identificación de Riesgos y Control en los Centros de Trabajo",
    },
    {id: 12,
        postImage: "assets/images/sostenibilidad/seg_trabajos_oc.jpg",
        postYear: "Noviembre, 2020",
        postName: "Seguridad en Trabajos de Obra Civil",
    },
    {id: 13,
        postImage: "assets/images/sostenibilidad/id_peligros_riesgos.jpg",
        postYear: "Octubre, 2020",
        postName: "Identificación de Peligros y Evaluación de Riesgos",
    },
    {id: 14,
        postImage: "assets/images/sostenibilidad/colores_senales.jpg",
        postYear: "Septiembre, 2020",
        postName: "Colores y Señales de Seguridad",
    },
    {id: 15,
        postImage: "assets/images/sostenibilidad/trabajos_izaje.jpg",
        postYear: "Septiembre, 2020",
        postName: "Trabajos de Izaje de Cargas",
    },
    {id: 16,
        postImage: "assets/images/sostenibilidad/estadisticas_sup_res.jpg",
        postYear: "Agosto, 2020",
        postName: "Estadísticas de Seguridad a Supervision y Residencia",
    },
    {id: 17,
        postImage: "assets/images/sostenibilidad/ast_quimicas.jpg",
        postYear: "Agosto, 2020",
        postName: "Realización de AST para Quimica del Rey",
    },
    {id: 18,
        postImage: "assets/images/sostenibilidad/induccion_quimicas.jpg",
        postYear: "Agosto, 2020",
        postName: "Inducción Seguridad para Quimica del Rey",
    },
    {id: 19,
        postImage: "assets/images/sostenibilidad/induccion_seg_sabinas.jpg",
        postYear: "Julio, 2020",
        postName: "Inducción de Seguridad para trabajo por 30 días en Sabinas",
    },
    {id: 20,
        postImage: "assets/images/sostenibilidad/cap_nueva_normalidad_psic.jpg",
        postYear: "Junio, 2020",
        postName: "Capacitación Psicología Nueva Normalidad COVID-19",
    },
    {id: 21,
        postImage: "assets/images/sostenibilidad/cap_nueva_normalidad.jpg",
        postYear: "Junio, 2020",
        postName: "Capacitación Seguridad Nueva Normalidad COVID-19",
    },
    {id: 22,
        postImage: "assets/images/sostenibilidad/camp_alto_manos.jpg",
        postYear: "Febrero, 2020",
        postName: 'Campaña "Alto, por tí y tu familia, cuida tus manos"',
    },
    {id: 23,
        postImage: "assets/images/sostenibilidad/rit_2020.jpg",
        postYear: "Febrero, 2020",
        postName: "Reuniones de Inicio de Turno (RIT)",
    },
    {id: 24,
        postImage: "assets/images/sostenibilidad/posada_operativo2019.jpg",
        postYear: "Diciembre, 2019",
        postName: "Posada Navideña Personal Operativo",
    },
    {id: 25,
        postImage: "assets/images/sostenibilidad/camp_actua_seguro.jpg",
        postYear: "Diciembre, 2019",
        postName: "Campaña 'Actúa seguro porque seguro vuelves a casa'",
    },
    {id: 26,
        postImage: "assets/images/sostenibilidad/peregrinacion_guadalupe2019.jpg",
        postYear: "Diciembre, 2019",
        postName: "Peregrinación Virgen de Guadalupe",
    },
    {id: 27,
        postImage: "assets/images/sostenibilidad/dale_carnegie_curso.jpg",
        postYear: "Noviembre, 2019",
        postName: "Curso Dale Carnegie Habilidades Esenciales para el Éxito",
    },
    {id: 28,
        postImage: "assets/images/sostenibilidad/altar_muertos_2019.jpg",
        postYear: "Noviembre, 2019",
        postName: "Altar de Muertos",
    },
    {id: 29,
        postImage: "assets/images/sostenibilidad/des_hab_liderazgo.jpg",
        postYear: "Noviembre, 2019",
        postName: "Desarrollo de Habilidades de Liderazgo",
    },
    {id: 30,
        postImage: "assets/images/sostenibilidad/platica_cancer_mama.jpg",
        postYear: "Octubre, 2019",
        postName: "Plática Informativa Cáncer de Mama",
    },
    {id: 31,
        postImage: "assets/images/sostenibilidad/tarde_mexicana.jpg",
        postYear: "Septiembre, 2019",
        postName: "Tarde Mexicana",
    },
    {id: 32,
        postImage: "assets/images/sostenibilidad/passt_sabinas.jpg",
        postYear: "Agosto, 2019",
        postName: "Obtención del Primer Nivel PASST Mantenimiento Sabinas",
    },
    {id: 33,
        postImage: "assets/images/sostenibilidad/certif_grua_movil.jpg",
        postYear: "Agosto, 2019",
        postName: "Certificación a Operadores de Grúa Móvil Industrial",
    },
    {id: 34,
        postImage: "assets/images/sostenibilidad/nuevo_autobus.jpg",
        postYear: "Julio, 2019",
        postName: "Nuevo Autobús de Transporte de Personal",
    },
    {id: 35,
        postImage: "assets/images/sostenibilidad/cap_ss_op.jpg",
        postYear: "Julio, 2019",
        postName: "Capacitación en Seguridad y Salud a Personal Operativo",
    },
    {id: 36,
        postImage: "assets/images/sostenibilidad/comite_clusmin.jpg",
        postYear: "Junio, 2019",
        postName: "Comité de Fortalecimiento de Proveedores del Clúster Minero de Zacatecas",
    },
    {id: 37,
        postImage: "assets/images/sostenibilidad/17_aniv.jpg",
        postYear: "Mayo, 2019",
        postName: "GRUPO CAVI 17 Aniversario",
    },
    {id: 38,
        postImage: "assets/images/sostenibilidad/dia_albanil_2019.jpg",
        postYear: "Mayo, 2019",
        postName: "Día del Trabajador de la Construcción",
    },
    {id: 39,
        postImage: "assets/images/sostenibilidad/rit_2019.jpg",
        postYear: "Marzo, 2019",
        postName: "Reuniones de Inicio de Turno (RIT)",
    },
    {id: 40,
        postImage: "assets/images/sostenibilidad/visita_club_leones.jpg",
        postYear: "Febrero, 2019",
        postName: "Visita Jardín de Niños 'Club de Leones'",
    },
    {id: 41,
        postImage: "assets/images/sostenibilidad/peregrinacion_candelaria.jpg",
        postYear: "Febrero, 2019",
        postName: "Peregrinación Virgen de la Candelaria",
    },
    {id: 42,
        postImage: "assets/images/sostenibilidad/visita_eee.jpg",
        postYear: "Enero, 2019",
        postName: "Visita a Escuela de Educación Especial",
    },
    {id: 43,
        postImage: "assets/images/sostenibilidad/posada_operativo.jpg",
        postYear: "Diciembre, 2018",
        postName: "Posada Personal Operativo",
    },
    {id: 44,
        postImage: "assets/images/sostenibilidad/nacimiento_2018.jpg",
        postYear: "Diciembre, 2018",
        postName: "Colocación de Nacimiento",
    },
    {id: 45,
        postImage: "assets/images/sostenibilidad/peregrinacion_guadalupe.jpg",
        postYear: "Diciembre, 2018",
        postName: "Peregrinación Virgen de Guadalupe",
    },
    {id: 46,
        postImage: "assets/images/sostenibilidad/altar_muertos.jpg",
        postYear: "Noviembre, 2018",
        postName: "Colocación de Altar de Muertos",
    },
    {id: 47,
        postImage: "assets/images/sostenibilidad/certif_mecanicos.jpg",
        postYear: "Octubre, 2018",
        postName: "Entrega de Certificados a Mecánicos",
    },
    {id: 48,
        postImage: "assets/images/sostenibilidad/cap_mecanicos.jpg",
        postYear: "Octubre, 2018",
        postName: "Capacitación a Mecánicos",
    },
    {id: 49,
        postImage: "assets/images/sostenibilidad/cap_albaniles.jpg",
        postYear: "Octubre, 2018",
        postName: "Capacitación a Albañiles",
    },
    {id: 50,
        postImage: "assets/images/sostenibilidad/certif_op_bulldozer.jpg",
        postYear: "Octubre, 2018",
        postName: "Entrega de Certificados a Operadores de Bulldozer",
    },
    {id: 51,
        postImage: "assets/images/sostenibilidad/certif_op_gruas.jpg",
        postYear: "Octubre, 2018",
        postName: "Entrega de Certificados a Operadores de Grúa",
    },
    {id: 52,
        postImage: "assets/images/sostenibilidad/cap_trabajos_alturas.jpg",
        postYear: "Septiembre, 2018",
        postName: "Capacitación para Trabajos en Alturas",
    },
    {id: 53,
        postImage: "assets/images/sostenibilidad/certif_op_montacargas.jpg",
        postYear: "Agosto, 2018",
        postName: "Certificación a Operadores de Montacargas",
    },
    {id: 54,
        postImage: "assets/images/sostenibilidad/curso_int_cianuro.jpg",
        postYear: "Agosto, 2018",
        postName: "Curso de Intoxicación por Cianuro de Sodio",
    },
    {id: 55,
        postImage: "assets/images/sostenibilidad/entrega_despensa.jpg",
        postYear: "Agosto, 2018",
        postName: "Entrega de Despensa",
    },
    {id: 56,
        postImage: "assets/images/sostenibilidad/certif_operadores_retro.jpg",
        postYear: "Julio, 2018",
        postName: "Certificación a Operadores de Retroexcavadora",
    },
    {id: 57,
        postImage: "assets/images/sostenibilidad/curso_soldadores.jpg",
        postYear: "Junio, 2018",
        postName: "Curso a Soldadores",
    },
    {id: 58,
        postImage: "assets/images/sostenibilidad/feria_seguridad.jpg",
        postYear: "Abril, 2018",
        postName: "Feria de la Seguridad Fresnilo PLC",
    },
  ];
  
export default sustainPosts;