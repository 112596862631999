import React from "react";

import Navbar from "../components/Navbar.js";
import Heading from "../components/Heading.js";
import VideosCatalog from "../components/VideosCatalog.js";
import Footer from "../components/Footer.js";

export default function Videos() {
  return (
    <>
      <Navbar/>
      <Heading hImage={`url('assets/images/fondo_videos.jpg')`} hTitle={'Videos'}/>
      <VideosCatalog />
      <Footer />
    </>
  );
}