import React from "react";

import Navbar from "../components/Navbar.js";
import Heading from "../components/Heading.js";
import BannerServices from "../components/BannerServices.js";
import ServicesCatalog from "../components/ServicesCatalog.js";
import ServicesSlider from "../components/ServicesSlider.js";
import maquicaviSlider from '../slider-maquicavi-list.js';
import concaviSlider from '../slider-concavi-list.js';
import cavimacSlider from '../slider-cavimac-list.js';
import Footer from "../components/Footer.js";

export default function Services() {
  return (
    <>
      <Navbar/>
      <Heading hImage={`url('assets/images/fondo_servicios.jpg')`} hTitle={'Servicios'}/>
      <BannerServices />
      <ServicesCatalog />
      <ServicesSlider slSubtitle={"Renta de Maquinaria"} slSitio={"https://maquicavi.com.mx"} slLogo={'assets/images/logo-maquicavi.jpg'} slImages={maquicaviSlider}/>
      <ServicesSlider slSubtitle={"Concreto Premezclado"} slSitio={"https://concavi.com.mx"} slLogo={'assets/images/logo-concavi.jpg'} slImages={concaviSlider}/>
      <ServicesSlider slSubtitle={"Fabricación y Montaje de Estructuras"} slSitio={"https://cavimac.com.mx"} slLogo={'assets/images/logo-cavimac.jpg'} slImages={cavimacSlider}/>
      <Footer />
    </>
  );
}