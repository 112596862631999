import React from 'react'

import ServiceItem from './ServiceItem';

export default function ServicesCatalog(){
    return(
        <>
            <section className="ftco-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center heading-section mb-5">
                            <span className="subheading">Servicios</span>
                            <h2 className="mb-3">Servicios Especiales</h2>
                        </div>
                    </div>
                    <div className="row">
                        <ServiceItem 
                            sIcon={"flaticon-montajes"}
                            sTitle={"Montajes de equipo"}
                            sDescription={"Diseño, fabricación y montaje de equipo y estructuras metálicas para naves industriales y otros tipos de edificaciones."}
                        />
                        <ServiceItem 
                            sIcon={"flaticon-tuberias"}
                            sTitle={"Instalación de tuberías"}
                            sDescription={"Elaboración e instalación de ductos y tuberías utilizados en la industria minera para el transporte de fluídos."}
                        />
                        <ServiceItem 
                            sIcon={"flaticon-plantas"}
                            sTitle={"Plantas de beneficio"}
                            sDescription={"Diseño y construcción de plantas de beneficio para tratamiento y procesamiento de minerales."}
                        />
                        <ServiceItem 
                            sIcon={"flaticon-obra-civil"}
                            sTitle={"Estructuras y Obra Civil"}
                            sDescription={"Diseño, elaboración y construcción de estructuras para edificaciones y proyectos de obra civil."}
                        />
                        <ServiceItem 
                            sIcon={"flaticon-paileria"}
                            sTitle={"Pailería industrial"}
                            sDescription={"Fabricación de silos, molinos, recipientes de acero al carbón, acero inoxidable y recubrimientos anticorrosivos para componentes de almacenamiento en plantas mineras."}
                        />
                        <ServiceItem 
                            sIcon={"flaticon-instrumentista"}
                            sTitle={"Línea instrumentista"}
                            sDescription={"Operaciones con diagramas hidráulicos y neumáticos, programación de PLC´S, supervisión de equipos de cribado, bombeo, equipo de compresión de aire y equipo de ventilación en plantas mineras."}
                        />
                        <ServiceItem 
                            sIcon={"flaticon-electrico"}
                            sTitle={"Línea eléctrica"}
                            sDescription={"Mantenimiento eléctrico a motores eléctricos, subestaciones eléctricas, operaciones en tableros de distribución, máquinas y equipos de plantas mineras."}
                        />
                        <ServiceItem 
                            sIcon={"flaticon-mecanico"}
                            sTitle={"Línea mecánica"}
                            sDescription={"Mantenimiento y reparación de sistemas electromecánicos, hidráulicos, neumáticos, mecánicos y de control, que conforman las máquinas y equipos de plantas mineras."}
                        />
                    </div>
                </div>
            </section>
        </>
    );
}