import React from 'react'

export default function Directors(){
    return(
        <>
            <section className="ftco-section ftco-no-pb ftco-no-pt">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-md-last d-flex align-items-stretch">
                            <div className="img w-100 img-2 mr-md-2" style={{backgroundImage: `url(assets/images/directores2.jpg)`}}></div>
                        </div>
                        <div className="col-md-6 wrap-about py-md-5">
                            <div className="heading-section pr-md-5">
                                <h2 className="mb-4">GRUPO CAVI</h2>
                                    <p align="justify">Nuestra historia surge en 2002 como CAVI de Sombrerete y una plantilla de 20 trabajadores, posterior en 2005 la creación de la empresa Montajes y Estructuras de Zacatecas. En 2013 con una plantilla mayor a 500 trabajadores, se recibe el GALARDON DE PLATA AL MERITO EMPRESARIAL por parte del Gobierno del Estado.
                                        <br></br>A inicios de 2014 se crea la empresa Concretos CAVI, su giro específico: tiro de concreto premezclado y bombeado. A partir de entonces inicia proyecto de sucursal que opera el adecuado control de infraestructura por operaciones de activo fijo; rentas a terceros por equipamientos en maquinaria pesada, equipo de transporte, campamentos, oficinas móviles y demás equipo industrial.
                                        <br></br>En 2017, festejamos nuestro 15 Aniversario de permanecer dentro del mercado, evento denominado “Unión y Perseverancia”.
                                        <br></br>Tras la contingencia mundial COVID 19, nos sentimos favorecidos por haber permanecido con trabajo y todo nuestro personal con buena salud y estabilidad emocional; hoy nos encontramos celebrando nuestro aniversario por 20 años de compromiso y resiliencia.
                                    </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}