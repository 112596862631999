import React from 'react'

import Navbar from "../components/Navbar.js";
import Heading from "../components/Heading.js";
import Footer from "../components/Footer.js";

export default function Contact(){

    return(
        <>
            <Navbar />
            <Heading hImage={`url('assets/images/fondo_contacto.jpg')`} hTitle={'Contacto'}/>
            <section className="ftco-section contact-section">
                <div className="container">
                    <div className="row d-flex mb-5 contact-info justify-content-center">
                        <div className="col-md-8">
                            <div className="row mb-5">
                                <div className="col-md-4 text-center py-4">
                                    <div className="icon">
                                        <span className="fa fa-map"></span>
                                    </div>
                                    <p><span>Dirección:</span> Benemérito de las Américas 137, Col. López Mateos, 99102 Sombrerete, ZAC.</p>
                                </div>
                                <div className="col-md-4 text-center border-height py-4">
                                    <div className="icon">
                                        <span className="fa fa-phone"></span>
                                    </div>
                                    <p><span>Teléfono:</span> <a href="tel://4339352833">(433) 935 2833</a></p>
                                </div>
                                <div className="col-md-4 text-center py-4">
                                    <div className="icon">
                                        <span className="fa fa-paper-plane"></span>
                                    </div>
                                    <p><span>Correo:</span> <a href="mailto:proyectos@grupocavi.com.mx">proyectos@grupocavi.com.mx</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>	
            <Footer />
        </>
    )
}