import React from 'react'

export default function ServiceItem(props){
    return(
        <>
            <div className="col-md-3 d-flex align-items-stretch">
                <div className="media block-6 services d-block text-center px-3 pb-4">
                    <div className="icon d-flex justify-content-center align-items-center">
                        <span className={props.sIcon}></span>
                    </div>
                    <div className="media-body py-md-4">
                        <h3>{props.sTitle}</h3>
                        <p>{props.sDescription}</p>
                    </div>
                </div>      
            </div>
        </>
    );
}