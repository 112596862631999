import React from 'react'

export default function VideoItem(props){
    return(
        <>
            <div className="col-md-6">
                <div className="property-details">
                    <div className="img" style={{backgroundImage:props.vThumbnail}}>
                        <a href={props.vLink} className="img-video popup-vimeo d-flex align-items-center justify-content-center">
                        <span className="fa fa-play"></span>
                        </a>
                    </div>
                    <div className="text">
                        <span className="subheading">{props.vName}</span>
                        <h2>{props.vYear}</h2>
                    </div>
                </div>
            </div>
        </>
    );
}