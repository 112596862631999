import React from 'react'

export default function Heading(props){
    return(
        <>
            <section className="hero-wrap" style={{backgroundImage: props.hImage}} data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-9 pb-0 text-center">
                            <p className="breadcrumbs">
                                <span className="mr-2">
                                    <a href="/">Inicio <i className="fa fa-chevron-right"></i></a>
                                </span>
                            </p>
                            <h1 className="mb-3 bread">{props.hTitle}</h1>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}